import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { gapable, layerable } from 'helpers/traits';
import scrollTo from 'helpers/scrollTo';
import AccordionSection from './accordion-section';

/** Wrapper */
const Wrapper = styled.div`
    ${gapable()};
`;

/**
 * Akkordion-Komponente
 * @param {array} props.items Accordion Items
 * @param {array} props.items.title Title eines Accordion-Items
 * @param {array} props.items.content Content eines Accordion-Items
 * @param {bool} props.allowMultipleOpen Optional: Ob mehrere Items offen sein dürfen
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {number} props.scrollOffset Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 */
class Accordion extends Component {
    static propTypes = {
        allowMultipleOpen: PropTypes.bool,
        items: PropTypes.arrayOf(
            PropTypes.shape({ title: PropTypes.string, content: PropTypes.node })
        ).isRequired,
        gap: gapable.propType,
        sectionLayer: layerable.propType,
        scrollToOffset: PropTypes.number,
    };

    static defaultProps = {
        allowMultipleOpen: false,
        sectionLayer: null,
        gap: null,
        scrollToOffset: 30,
    };

    state = {
        openSections: {},
    };

    /* eslint-disable-next-line */
    sectionRefs = this.props.items.map(() => React.createRef());

    /**
     * Mount
     */
    componentDidMount() {
        const openSections = {};
        const { items } = this.props;

        items.forEach(item => {
            if (item.isOpen) {
                openSections[item.title] = true;
            }
        });

        this.setState({
            openSections,
        });
    }

    /**
     * Öffnet bzw. schließt Accordion-Items
     * @param {string} title Titel (Label des Accordions - muss eindeutig sein)
     */
    toggleAccordionItem = (title, ref) => {
        const { allowMultipleOpen, scrollToOffset } = this.props;
        const { openSections } = this.state;

        const isOpen = !!openSections[title];

        // Zum Element scrollen (mit Offset für potentiell offene StickyNav)
        scrollTo(ref.current, scrollToOffset);

        if (allowMultipleOpen) {
            this.setState({
                openSections: {
                    ...openSections,
                    [title]: !isOpen,
                },
            });
        } else {
            this.setState({
                openSections: {
                    [title]: !isOpen,
                },
            });
        }
    };

    /**
     * Render
     */
    render() {
        const { items, gap, sectionLayer } = this.props;
        const { openSections } = this.state;

        return (
            <Wrapper gap={gap}>
                {items.map((item, index) => (
                    <AccordionSection
                        layer={sectionLayer}
                        isOpen={!!openSections[item.title]}
                        title={item.title}
                        subtitle={item.subtitle}
                        ref={this.sectionRefs[index]}
                        onClick={() =>
                            this.toggleAccordionItem(item.title, this.sectionRefs[index])
                        }
                        key={item.title}
                    >
                        {item.content}
                    </AccordionSection>
                ))}
            </Wrapper>
        );
    }
}

export default Accordion;
