import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Intro from 'ui-kit/intro';
import Section from 'ui-kit/section';
import Stage from 'ui-kit/stage';
import Accordion from 'ui-kit/accordion';
import Container from 'ui-kit/container';
import Button from 'ui-kit/button';
import { P, Headline } from 'ui-kit/typography';

import Layout from '../../components/layout';
import TeaserSection from '../../components/teaser-section';

const FAQPage = ({ data }) => {
    /** Bilder */
    const stageImage = data.stageImage.childImageSharp;
    const faq = data.allFaqJson.edges;

    /** Inhalt des FAQ-Accordions aus JSON wrappen */
    const faqContents = faq.map(({ node: topic }, index) => {
        const { questions } = topic;
        const notLast = index !== faq.length - 1;
        const subaccordionItems = questions.map(({ title, answer, detailLink }) => ({
            title,
            content: (
                <>
                    <P gap={detailLink && 'l'}>{answer}</P>
                    {detailLink && <Button linkTo={detailLink}>Mehr erfahren</Button>}
                </>
            ),
        }));

        return (
            <Container gap={notLast ? 'xxl' : null} key={topic.title}>
                <Headline level="h3" gap="l">
                    {topic.title}
                </Headline>
                <Accordion items={subaccordionItems} />
                {notLast && <hr />}
            </Container>
        );
    });

    return (
        <Layout description="FAQ">
            <Stage
                image={stageImage}
                tag="Service & Kontakt | FAQ"
                headline={{ text: 'Wir helfen Ihnen gerne weiter', level: 'h1' }}
                imageAlt="Kinderhand hält einen Strauß Wildblumen"
                bottomShade
            />
            <Section>
                <Intro title="FAQ – Alles was Sie wissen möchten">
                    Hier finden Sie häufige Fragen und ehrliche Antworten zu den Themen Fleisch &
                    Wurst sowie zu unserem Unternehmen.
                </Intro>
            </Section>
            <Section container="l" bg="gray">
                {faqContents}
            </Section>
            <TeaserSection />
        </Layout>
    );
};

FAQPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        allFaqJson: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(
            relativePath: { eq: "service-and-contact-pages/buehne-service-und-kontakt.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        allFaqJson {
            edges {
                node {
                    title
                    questions {
                        title
                        answer
                        detailLink
                    }
                }
            }
        }
    }
`;

export default FAQPage;
